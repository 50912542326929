export const ACTION_PROPERTY_ADD = 'ACTION_PROPERTY_ADD'
export const ACTION_PROPERTY_UPDATE = 'ACTION_PROPERTY_UPDATE'
export const ACTION_PROPERTY_LIST = 'ACTION_PROPERTY_LIST'
export const ACTION_PROPERTY_LIST_FAIL = 'ACTION_PROPERTY_LIST_FAIL'
export const ACTION_PROPERTY_GET = 'ACTION_PROPERTY_GET'
export const ACTION_PROPERTY_GET_FAIL = 'ACTION_PROPERTY_GET_FAIL'
export const ACTION_SELL_TOKEN_PROPERTIES_LIST = 'ACTION_SELL_TOKEN_PROPERTIES_LIST'
export const ACTION_SELL_TOKEN_PROPERTIES_LIST_FAIL = 'ACTION_SELL_TOKEN_PROPERTIES_LIST_FAIL'
export const ACTION_GET_ID = 'ACTION_GET_ID'
export const ACTION_GET_UTILITY = 'ACTION_GET_UTILITY'
